@import "./src/assets/scss/main.scss";



















































































.modal-body {
    padding: 40px 31px 0 32px;
}

@media (min-width: 200px) {
    .modal-dialog {
        max-width: 1300px;
        padding-top: 60px;
    }
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.btn-close {
    position: absolute;
    background: none;
    position: absolute;
    top: -50px !important;
    margin: auto !important;
    width: 10px;
    left: 0 !important;
    right: 0 !important;
    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.modal-content {
    background: #f5f5f5;
}
.modal-conten--white{
    background: #fff;
}
